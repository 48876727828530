import Link from "next/link";
import React from "react";
import { useEffect } from "react";
import GenericLayout from "layouts/generic";
import css from "./index.module.scss";
import Button from "@/components/button";

export default function FourOhFourPage() {
  return (
    <GenericLayout
      title="&#x1F4C8; Innovative Growth Marketing Agency (Trusted)"
      description="We are a cutting-edge growth marketing agency helping our clients achieve unprecedented results through growth hack marketing."
    >
      <section className={`section ${css.fold}`}>
        <div className="sectionInner">
          <h1><b>404</b> | Not Found</h1>
          <div className={css.subheadline}>This page does not exist</div>

          <div className={css.buttons}>
            <Button label="Home" href="/" light ghost/>
            <Button label="Services" href="/services" light ghost/>
            <Button label="Contact Us" href="/contact" light ghost/>
          </div>
        </div>
      </section>

    </GenericLayout>
  );
}

/*
404 notice
Top page links
CTA button



*/
